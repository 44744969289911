<template>
  <div class="header">
    <img src="@/assets/header-logo.svg"/>
  </div>
  <div class="login">
    <div class="content">
      <div class="title">
        <img src="@/assets/title.svg"/>
      </div>
      <p class="signin">Administrator Sign in</p>
      <div class="form">
        <label>
          E-mail address
        </label>
        <div>
          <input placeholder="username@email.com" type="text" v-model="email" autocomplete="off" />
        </div>
        <label>
          Password
        </label>
        <div>
          <input placeholder="Passeword" type="password"  v-model="password" autocomplete="off" />
        </div>
        <div
          class="error">
          {{error}}
        </div>
        <p class="forgot-password">
          <a target="_blank" href="https://www.prontest-series.com/series/forgotPassword.html">Forgot password？</a>
        </p>
        <div class="login-btns">
          <button
            @click="login"
            :disabled="disableLogin"
            class="login-btn">Sign in</button>
        </div>
        <div class="login-sso">
          <div class="sso-btn google" @click="googleLogin">
            <font-awesome-icon icon="fa-brands fa-google" />  Google Login
          </div>
          <div class="sso-btn microsoft" @click="microsoftLogin">
            <font-awesome-icon icon="fa-brands fa-microsoft" />  Microsoft Login
          </div>
        </div>
        <p>
          <a target="_blank" href="https://www.prontest-series.com/series/signup.html">
            Sign in to Prontest Series with your email address
          </a>
        </p>
      </div>
    </div>
    <div class="img">
      <img src="@/assets/login-banner.jpg"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      error: null,
      email: null,
      password: null,
    }
  },
  computed: {
    disableLogin() {
      return !(this.email && this.password)
    },
  },
  async created() {
    const idToken = this.$route.query.idToken
    const type = this.$route.query.type
    if (idToken && type == 'azure') {
      await this.$api.post("/manager/auth/azureLogin", {idToken: idToken}).then(res => {
        this.saveLoginResult(res)
      }).catch(error => {
        this.error = error.data.message
      })
    }
  },
  methods: {
    login() {
      console.log('login')
      console.log(process.env.VUE_APP_API_BASE_URL)
      this.$api.post("/manager/auth/login", {
        username: this.email,
        password: this.password,
      }, {
        hideErrorDialog: true
      }).then(res => {
        this.saveLoginResult(res)
      }).catch(error => {
        this.error = error.data.message
      })
    },
    async googleLogin() {
      const googleUser = await this.$gAuth.signIn()
      if (!googleUser) return false
      await this.$api.post("/manager/auth/googleLogin", {idToken: googleUser.getAuthResponse().id_token}).then(res => {
        this.saveLoginResult(res)
      }).catch(error => {
        this.error = error.data.message
      })
    },
    microsoftLogin() {
      const host = process.env.VUE_APP_SERIES_HOST
      const state = parseInt(Math.random()*10000000)
      const nonce = parseInt(Math.random()*10000000)
      const url = encodeURIComponent(`${host}/api/login/redirect/azure/toefl/manager`)
      location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?` +
        `client_id=00eff8d7-60fb-4ddc-bdf2-4b5358734868` +
        `&response_type=id_token&redirect_uri=${url}` +
        `&response_mode=form_post&scope=email+openid+profile&state=${state}&nonce=${nonce}`
    },
    saveLoginResult(res) {
      this.$store.commit('setAuthInfo', {
        idToken: res.data.idToken,
        refreshToken: res.data.refreshToken,
        toeflAppId: res.data.toeflAppId,
        hasLicense: res.data.hasLicense,
        isManager: res.data.hasLicense,
      })
      if (res.data.hasLicense) {
        this.$router.push('/manager/menu')
      } else {
        this.error = '管理者権限ありません'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  text-align: left;
  padding-top: 10px;
  padding-left: 10px;
  img {
    height: 100%;
  }
}
.login {
  display: flex;
  ::placeholder {
    color: #D1D1D1;
  }
  a {
    text-decoration: none;
    color: black;
  }
  .error {
    color: red;
    font-size: 12px;
    text-align: left;
    font-weight: bold;
    margin-top: 5px;
    height: 14px;
  }
  .content {
    flex: 1.3;
    padding-top: 10px;
    .title {
      font-size: 35px;
      font-weight: 880;
      color: #ffec00;
      span {
        font-size: 20px;
      }
      img {
        width: 380px;
      }
    }
    .signin {
      margin-top: 20px;
      font-weight: bold;
      font-size: 32px;
    }
    .form {
      padding: 20px 20%;
      label {
        display: block;
        text-align: left;
        margin-top: 15px;
      }
      input {
        border: none;
        border-bottom: 1px solid;
        background-color: white;
        width: 100%;
        font-size: 14px;
        line-height: 180%;
        padding: 5px 0px;
      }
      input:focus {
        background-color: white;
      }
      .forgot-password {
        margin-top: 30px;
        display: inline-block;
        border-bottom:1px solid black;
        padding-bottom:2px;
      }
      .login-btns {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 5px;
        .login-btn {
          background: white;
          border: 1px solid;
          border-radius: 5px;
          padding: 8px 15px;
          cursor: pointer;
        }
        .login-google {
          height: 38px;
          cursor: pointer;
        }
      }
      .login-sso {
        display: flex;
        align-items: center;
        margin-top: 10px;
        justify-content: space-between;

        .google {
          background: #4285f4;
        }

        .microsoft {
          background: #545455;
        }

        .sso-btn {
          padding: 10px;
          border: none;
          border-radius: 4px;
          margin: 5px 0;
          opacity: 0.85;
          display: inline-block;
          font-size: 14px;
          line-height: 20px;
          text-decoration: none;
          color: white;
        }

        .sso-btn:hover {
          cursor: pointer;
          opacity: 1;
        }
      }
    }
  }
  .img {
    flex: 1;
    display: grid;
    align-items: center;
    img {
      width: 100%;
      margin-left: -20px;
    }
  }
}
</style>
